import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "components/atoms/toast/toast-context";
import { constants, phoneRegExp } from "constants/constants";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import * as Sentry from "@sentry/nextjs";

import { useEffect, useState } from "react";
// import { IApiError } from "services/api/api.types";
import { createCallMeBackRequest } from "services/api/call-me-back/callMeBackService";
import { ICreateCallMeBackRequestPayload } from "services/api/call-me-back/callMeBackService.types";
import trackEvent from "services/mixpanel/mixpanel";
import { bannerIntentEventNames } from "services/mixpanel/tanya-events";
import { useAuth } from "stores/auth/auth-context";
import { UserType } from "types/common/common.types";
import { parseAPIErrorResponse } from "utils/utils";
import {
  ContactMeFormSteps,
  ContactMeFormValues,
  IUseContactMe,
  IUseContactMeProps
} from "./use-contact-me.types";

const FormSchema = yup.object().shape({
  callbackRequestedBy: yup
    .mixed<UserType>()
    .oneOf(Object.values(UserType))
    .required("Isi bagian ini juga, ya"),
  name: yup.string().min(2, "Tulis minimal 2 karakter, ya.").required(),
  phoneNumber: yup
    .string()
    .required("Masukkan minimal 9 angka di sini")
    .matches(phoneRegExp, "Masukkan minimal 9 angka di sini")
    .min(8, "Masukkan minimal 9 angka di sini")
    .max(13, "Masukkan minimal 9 angka di sini"),
  grade: yup.string().required("Pilih kelas dulu, ya")
});

export const useContactMe = ({
  metaData,
  onClose,
  leadSource
}: IUseContactMeProps): IUseContactMe => {
  const { user } = useAuth();

  const primaryUser = user?.userData?.primary_user;
  const [formStep, setFormStep] = useState(
    ContactMeFormSteps.CAPTURE_USER_DETAILS
  );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid }
  } = useForm<yup.InferType<typeof FormSchema>>({
    defaultValues: {
      phoneNumber: primaryUser?.phone_number || "",
      name: primaryUser?.full_name || "",
      grade: primaryUser?.grade,
      callbackRequestedBy: UserType.STUDENT
    },
    resolver: yupResolver(FormSchema),
    mode: "onChange"
  });
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    trackEvent({
      eventName: bannerIntentEventNames.leadModalViewed,
      payload: { leadSource, ...metaData }
    });
  }, []);

  const onUserDetailsFormSubmit: SubmitHandler<ContactMeFormValues> = async ({
    callbackRequestedBy,
    phoneNumber,
    grade,
    name
  }) => {
    setIsLoading(true);

    trackEvent({
      eventName: bannerIntentEventNames.leadSubmitButtonClicked,
      payload: {
        leadSource,
        leadMobile: phoneNumber,
        leadGrade: grade,
        leadName: name,
        ...metaData
      }
    });

    const payload: ICreateCallMeBackRequestPayload = {
      user_type: callbackRequestedBy,
      phone_number: phoneNumber,
      name,
      grade,
      source_page: leadSource || ""
    };

    try {
      const resp = await createCallMeBackRequest(
        payload,
        primaryUser?.access_token
      );
      setIsLoading(false);
      // Show reason for joining textbox by updating the formStep
      setFormStep(ContactMeFormSteps.DETAILS_ENTERED_SUCCESSFULLY);
      return !!resp.data;
    } catch (err) {
      Sentry.captureException(err);
      const error = parseAPIErrorResponse(err);
      // const { message } = error as IApiError;
      addToast({
        heading: constants.DEFAULT_UNEXPECTED_ERROR_MESSAGE,
        variant: "error"
      });
      setIsLoading(false);
      throw error; // This is thrown so that isSubmitSuccessful does not become true inside useForm hook
    }
  };

  const onJoiningReasonFormSubmit = async (data: { reason: string }) => {
    const values = getValues();
    setFormStep(ContactMeFormSteps.DETAILS_ENTERED_SUCCESSFULLY);

    // Save reason to mixpanel
    trackEvent({
      eventName: bannerIntentEventNames.leadReasonSubmitClicked,
      payload: {
        leadMobile: values.phoneNumber,
        leadSource,
        leadGrade: values.grade,
        ...metaData,
        needBimbelReason: data.reason
      }
    });
  };

  const handleClose = () => {
    const values = getValues();
    if (formStep === ContactMeFormSteps.CAPTURE_USER_DETAILS) {
      trackEvent({
        eventName: bannerIntentEventNames.leadSkipButtonClicked,
        payload: {
          leadMobile: values.phoneNumber,
          leadSource,
          leadGrade: values.grade,
          ...metaData
        }
      });
      onClose();
    } else if (formStep === ContactMeFormSteps.CAPTURE_REASON_FOR_JOINING) {
      trackEvent({
        eventName: bannerIntentEventNames.leadReasonSkipButtonClicked,
        payload: {
          leadMobile: values.phoneNumber,
          leadSource,
          leadGrade: values.grade,
          ...metaData
        }
      });
      setFormStep(ContactMeFormSteps.DETAILS_ENTERED_SUCCESSFULLY);
    } else {
      onClose();
    }
  };

  const handleSuccessClose = () => {
    const values = getValues();
    trackEvent({
      eventName: bannerIntentEventNames.leadOkayButtonClickedOnSuccess,
      payload: {
        leadMobile: values.phoneNumber,
        leadSource,
        leadGrade: values.grade,
        ...metaData
      }
    });
    onClose();
  };

  return {
    control,
    handleSubmit,
    isValid,
    onUserDetailsFormSubmit,
    onJoiningReasonFormSubmit,
    isLoading,
    formStep,
    getValues,
    handleClose,
    handleSuccessClose
  };
};
